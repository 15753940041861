#a29ee8ad-7f56-4b58-99d1-22120af004c5 {
    margin-top: 0 !important;
}

.image--background .image__img {
    max-width: none;
}
#ea4bb855-7a88-4bb1-85e0-0e9f57b0159f h1{
    font-size: 3.5em; font-size: min(max(21.0000px, 3.75vw), 3.5em); font-size: clamp(21.0000px, 3.75vmax, 3.5em); line-height: 1;
}
#ea4bb855-7a88-4bb1-85e0-0e9f57b0159f h1 span{
    color: #000000; font-weight: 600;
}
#s3a444c92-2880-4eec-9c11-83dbdd11f03f p span{
    color: #ffffff; font-size: 20px; font-size: min(max(1rem, 1.88vw), 20px); font-size: clamp(1rem, 1.88vmax, 20px);
}