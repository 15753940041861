.text-center{
    align-items: center;
}
.d-flex{
    display:flex;
} 
.justify-content-center{
    justify-content:center;
}
.w-100{
    width:100%;
}
#SHcc6aab27-f4fd-4eef-bd20-50a8fa58eb55{
    padding: 16px 0;
    margin-top: 50px;
}
#SHcc6aab27-f4fd-4eef-bd20-50a8fa58eb55 h1, h1{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif; text-align: center;
    font-size: 3.125em;
}
.form--inline-labels label{
    display: flex;
    align-items: center;
    position:relative;
}
.form--inline-labels label:last-child{
    margin-bottom:0;
}

.form--inline-labels label span.form__label {
    flex: 1 1 250px;
}
.form--inline-labels label > div{
    width:100%;
    margin-top:0;
}
.form--inline-labels .form__switch {
    display: flex;
    justify-content: space-between;
}
.form--inline-labels .form__switch span {
    display: flex;
    justify-content: start;
    flex: 1 1 250px;
    margin-top: 0;
}
input[type="text"], input[type="email"], input[type="tel"], select, textarea,
.form--inline-labels input,
.form--inline-labels select{
    background-color: #f2f2f2!important;
    color: #1d1d1d!important;
    border: none!important;
    border-radius: 2px!important;
}

.input-error input, .input-error select, .input-error textarea{
    border-bottom-color: #C01D1C!important;  
}
.input-error .form__label{
    color:#C01D1C;
}
.input-error .message{
    position: absolute;
    margin-top: 0;
    font-size: .75rem;
    color:#C01D1C;
}
.input-error .form__privacy .message{
    left:50%;
    transform:translateX(-50%);
}

form{
    position:relative;
}
form .sending-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 100%;
    background: rgba(255,255,255,.7);
    margin-top:0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-response-container{
    position:relative;
    background: #ffffff;
    border: 1px solid #54a52c;
    padding: 2rem;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    text-align:center;
}
.form-repsonse__close{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0!important;
    padding: 0.5rem;
  
}

.stashhouseModal__overlay{
    z-index:1;
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
}
.stashhouseModal h1{
    font-size: 3.125em;
}
.stashhouseModal .ReactModal__Content{
    padding: 3.125em!important;
}
.ReactModal__Content{
    border: 1px solid #54a52c!important;
    border-radius: 0!important;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
}

.forgot-password-form .input-error .form__label{
    color:#1d1d1d;
}