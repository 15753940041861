.link-region__link.floating-contact{
    display:inline-block;
    background-color: var(--color-primary);
    position:fixed;
    bottom:0;
    left:0;
    z-index:10001;
}
.floating-contact-container .sticky{
    z-index:10001;
}
body:not(.editor-frame) .image--contact-icon {
    width: 50px;
    padding: 1rem;
}

body:not(.editor-frame) .containerv2--floating-contact {
    pointer-events: none;
}

body:not(.editor-frame) .containerv2--floating-contact .link-region {
    pointer-events: auto;
}
@media(max-width: 64rem) {
    body:not(.editor-frame) .image--contact-icon {
        width: 40px;
        padding: 0.75rem;
    }
}
