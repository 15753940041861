

.iFrame-video-container{
    height:0;
    aspect-ratio: 16 / 9;
    position:relative!important;
    overflow:hidden;
}

.iFrame-video-container iframe{
    opacity:1!important;
}