#SH9aeb6a21-4542-4c55-8e15-bd35cfc2b20a{
    padding: 75px;
}
#SH9aeb6a21-4542-4c55-8e15-bd35cfc2b20a > .containerv2__regions{
    max-width:1386px;
}
#SH9aeb6a21-4542-4c55-8e15-bd35cfc2b20a h1{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
    font-size: 3.125em;
}
#SH76bf3fda-c21a-48cd-b62d-9a44c99148bb{
    max-width: 410px;
    position:relative;
}
#SH21004d03-d174-4f54-b5c7-8dbff5d10528{
    max-width: 531px;
    position:relative;
}
#SH21004d03-d174-4f54-b5c7-8dbff5d10528 img,
#SH76bf3fda-c21a-48cd-b62d-9a44c99148bb img{
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
}
#SH5dcbe953-aba5-4ecd-ba66-dfcd1505c4b9 p{
    font-family: montserrat, 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 19px;
    font-size: min(max(1rem, 1.82vw), 19px);
    font-size: clamp(1rem, 1.82vmax, 19px);
    font-weight: bold;
}

#SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5,
#SH35fcf078-aca6-4065-a7b4-ea98f7593153,
#SH722da5e9-4273-4f96-bd7d-2746d57642d6{
    max-width:1386px;
}

#SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5{
    padding-top:80px;
}
#SHe7141856-8ba3-4c23-a512-c6c0d0d1e84f,
#SH71746aa8-2ef9-4fab-b5cf-0ab5dff13879,
#SH71746aa8-2ef9-4fab-b5cf-0ab5dff13876,
#SH71746aa8-2ef9-4fab-b5cf-0ab5dff1380b,
#SH8c1aaa00-9388-4f3b-9cb5-561cbc74c6e1{
    padding-bottom:80px;
}
#SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5 .text-content ul,
#SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5 .text-content ol {
    padding: 0 0 0 15px;
}

#SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5 .text-content li {
    padding-left: 20px;
}

#SH8de03ce9-9adb-4fdd-8e48-ca2c6bdd2dc5 .text-content li::marker {
    content: '--';
    letter-spacing: -2px;
}
