
form {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto
}

form input[type=hidden]+* {
    margin-top: 0
}

input[type=date],
input[type=datetime],
input[type=time],
input[type=email],
input[type=number],
input[type=search],
input[type=text],
input[type=tel],
input[type=url],
input[type=password],
input[type=file],
textarea,
select {
    height: 2.375em;
    padding: .375em .625em;
    background-color: #f2f2f2;
    color: #1d1d1d;
    border: none;
    border-radius: 2px;
    box-shadow: none;
    box-sizing: border-box;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    vertical-align: middle;
    text-transform: none;
    font-size: .9375em
}

@media(min-width: 40rem) {

    input[type=date],
    input[type=datetime],
    input[type=time],
    input[type=email],
    input[type=number],
    input[type=search],
    input[type=text],
    input[type=tel],
    input[type=url],
    input[type=password],
    input[type=file],
    textarea,
    select {
        font-size: .9375em;
        height: 2.75em
    }
}

.a11y input[type=date],
.a11y input[type=datetime],
.a11y input[type=time],
.a11y input[type=email],
.a11y input[type=number],
.a11y input[type=search],
.a11y input[type=text],
.a11y input[type=tel],
.a11y input[type=url],
.a11y input[type=password],
.a11y input[type=file],
.a11y textarea,
.a11y select {
    background-color: #e4f0f3;
    color: #000
}

.a11y input[type=date]:hover,
.a11y input[type=datetime]:hover,
.a11y input[type=time]:hover,
.a11y input[type=email]:hover,
.a11y input[type=number]:hover,
.a11y input[type=search]:hover,
.a11y input[type=text]:hover,
.a11y input[type=tel]:hover,
.a11y input[type=url]:hover,
.a11y input[type=password]:hover,
.a11y input[type=file]:hover,
.a11y textarea:hover,
.a11y select:hover {
    outline: 3px solid #00bfff
}

input[type=file] {
    line-height: 1.8
}

::-webkit-input-placeholder {
    color: rgba(29, 29, 29, .4)
}

:-ms-input-placeholder {
    color: rgba(29, 29, 29, .4) !important
}

::-ms-input-placeholder {
    color: rgba(29, 29, 29, .4)
}

:-moz-placeholder {
    color: rgba(29, 29, 29, .4)
}

::-moz-placeholder {
    color: rgba(29, 29, 29, .4)
}

::placeholder {
    color: rgba(29, 29, 29, .4);
    opacity: 1
}

select::-ms-expand {
    display: none
}

select {
    padding-right: 2rem
}

select optgroup,
select option {
    color: initial
}

textarea {
    min-height: 8em
}

textarea[rows] {
    height: auto
}

label,
legend {
    display: block;
    position: relative
}

label:last-child,
legend:last-child {
    margin-bottom: 0
}

fieldset[aria-hidden=true],
label[aria-hidden=true] {
    display: none
}

fieldset {
    padding: 0;
    border-width: 0;
    margin-left: 0;
    margin-right: 0
}

input[type=checkbox],
input[type=radio] {
    display: inline
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: rgba(0, 0, 0, 0) !important
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none
}

input[type=range]::-moz-range-thumb {
    border: none
}

input[type=range]:focus {
    outline: none
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0)
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 3px 2px 9px 0px rgba(68, 68, 68, .18);
    height: 2em;
    width: 2em;
    border-radius: 1em;
    background: #ffffed;
    cursor: pointer;
    margin-top: -0.875em
}

input[type=range]::-moz-range-thumb {
    box-shadow: 3px 2px 9px 0px rgba(68, 68, 68, .18);
    height: 2em;
    width: 2em;
    border-radius: 1em;
    background: #ffffed;
    cursor: pointer;
    margin-top: -0.875em
}

input[type=range]::-ms-thumb {
    box-shadow: 3px 2px 9px 0px rgba(68, 68, 68, .18);
    height: 2em;
    width: 2em;
    border-radius: 1em;
    background: #ffffed;
    cursor: pointer;
    margin-top: -0.25em
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: currentColor
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #d8d8db
}

input[type=range]::-moz-range-progress {
    background-color: currentColor
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    border-width: 1em 0;
    color: rgba(0, 0, 0, 0)
}

input[type=range]::-ms-fill-lower {
    background: currentColor
}

input[type=range]:focus::-ms-fill-lower {
    background: #00bfff
}

input[type=range]::-ms-fill-upper {
    background: #d8d8db
}

.form__header {
    text-align: center;
    margin-bottom: 2.25rem
}

.form__label+input[type=date],
.form__label+input[type=datetime],
.form__label+input[type=time],
.form__label+input[type=email],
.form__label+input[type=number],
.form__label+input[type=search],
.form__label+input[type=text],
.form__label+input[type=tel],
.form__label+input[type=url],
.form__label+input[type=password],
.form__label+input[type=file],
.form__label+textarea,
.form__label+select {
    margin-top: .25em
}

.form .form__error {
    padding: .5em;
    margin: 0;
    font-size: .875em;
    color: #dc3545;
    max-height: none;
    opacity: 1
}

.form .form__error[aria-hidden=true] {
    visibility: hidden;
    max-height: 0;
    padding: 0;
    transition: max-height 400ms ease-out, padding 400ms ease-out, opacity 400ms ease-out;
    opacity: 0
}

.form .form__error *+* {
    margin-top: 0
}

.form .form__error li {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
    font-size: .9375rem
}

.form .form__error li span {
    color: inherit !important;
    font-size: inherit;
    line-height: 1.5
}

.form .form__error .error__icon {
    top: auto
}

.form .form__error .error__icon svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle
}

.form__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0
}

.form__switch span {
    display: flex;
    justify-content: center;
    flex: 1 1 50%;
    margin-top: 0
}

.form__switch--element {
    display: flex;
    justify-content: center;
    flex: 1 1 50%;
    margin-top: 0;
    text-align: right
}

.form__switch--element * {
    margin-top: 0
}

.form__switch--element label {
    display: block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid currentColor;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    box-shadow: 0 0 20px rgba(71, 122, 133, .3137254902)
}

.form__switch--element label::after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #e8f5f7;
    position: absolute;
    border-radius: 70px;
    top: 0px;
    left: 0px;
    transition: .5s
}

.form__switch--element label::before {
    content: "";
    width: 6px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
    position: absolute;
    top: 2px;
    left: 6px;
    z-index: 5;
    transform: rotate(45deg);
    opacity: 0;
    transition: .5s
}

.form__switch--element input[type=checkbox]:checked+label {
    background-color: var(--color-primary)
}

.form__switch--element input[type=checkbox]:checked+label:after {
    left: 100%;
    transform: translateX(-100%)
}

.form__switch--element input[type=checkbox]:checked+label:before {
    opacity: 100;
    left: calc(100% - 6px);
    transform: translateX(-100%) rotate(45deg)
}

body:not(.a11y) .form__switchlegend {
    display: none
}

.form__checkbox--inline {
    display: inline-block;
    margin-right: 1rem
}

@media all and (min-width: 30rem) {
    .form__checkbox--inline {
        margin-right: 1.25rem
    }
}

.form__checkbox--container {
    display: flex;
    align-items: center;
    justify-content: center
}

.form__checkbox--container input,
.form__checkbox--container label {
    margin-top: 0
}

.form__checkbox--container-text {
    margin-right: 1rem
}

@media all and (min-width: 30rem) {
    .form__checkbox--container-text {
        margin-right: 1.25rem
    }
}

.form__checkbox-icon {
    display: none;
    vertical-align: middle;
    margin-top: 0;
    font-style: normal
}

.form__checkbox::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2
}

.a11y .form__checkbox:hover {
    outline: 3px solid #00bfff
}

.form__checkbox input[type=checkbox],
.form__checkbox input[type=radio] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    margin-top: 18px;
    margin-left: 8px
}

.form__checkbox-icon:first-of-type,
input[type=checkbox]:checked+.form__checkbox .form__checkbox-icon:last-of-type,
input[type=radio]:checked+.form__checkbox .form__checkbox-icon:last-of-type {
    display: inline-block
}

input[type=checkbox]:checked+.form__checkbox .form__checkbox-icon:first-of-type,
input[type=radio]:checked+.form__checkbox .form__checkbox-icon:first-of-type {
    display: none
}

.showfocus input[type=checkbox]:focus+.form__checkbox,
.showfocus input[type=radio]:focus+.form__checkbox {
    outline: 3px solid #00bfff !important
}

.form__disclaimer {
    font-size: .75em !important;
    line-height: 1.4;
    font-style: italic;
    padding: 0;
    margin-top: 0
}

.form__privacy {
    text-align: center;
    position: relative;
    z-index: 0
}

.form__privacy-check {
    margin-top: .675rem
}

.form__privacy-check .form__privacy-checkboxes {
    margin-top: .5rem
}

.form__privacy-check .form__privacy-checkboxes>* {
    margin-top: 0
}

@media all and (min-width: 1024px) {
    .form__privacy-check .form__privacy-checkboxes {
        margin-top: 0
    }
}

@media all and (min-width: 1024px) {
    .form__privacy-check {
        display: flex;
        justify-content: center;
        align-items: center
    }
}

.form__required::after {
    content: " *";
    vertical-align: super;
    font-size: .75em;
    color: #dc3545
}

.form__select {
    display: block;
    position: absolute;
    right: .75rem;
    bottom: calc(var(--error-height, 0px) + 1.375rem);
    transform: translateY(50%) rotate(45deg);
    pointer-events: none;
    width: .5rem;
    height: .5rem;
    border-style: solid;
    border-width: 0 .125rem .125rem 0;
    border-color: #1d1d1d
}

.a11y .form__select {
    border-color: #000
}

.form__h3 {
    font-family: var(--typeface-secondary), Georgia, "Times New Roman", Times, serif;
    font-size: 1.5em;
    font-weight: 400
}

@media all and (min-width: 70rem) {
    .form__h3 {
        font-size: 1.875em
    }
}

.form__rating {
    display: flex;
    flex-direction: column
}

@media all and (min-width: 48em) {
    .form__rating {
        flex-direction: row;
        align-items: center
    }
}

.form__rating__stars {
    flex: 1 1 auto;
    margin: 0;
    color: inherit
}

@media all and (min-width: 48em) {
    .form__rating__stars {
        text-align: right
    }
}

.form__rating__stars :last-child {
    margin-right: 0
}

.form__rating__stars .form__checkbox {
    margin-top: 0;
    margin-right: .25rem;
    padding: .5rem 0
}

.form__rating__stars .form__star-icon {
    display: inline-block;
    margin-top: 0
}

.form__rating__stars .form__star-icon:first-of-type {
    display: none
}

input[type=checkbox]:checked+.form__rating__stars .form__checkbox .form__rating__stars .form__star-icon:last-of-type,
input[type=radio]:checked+.form__rating__stars .form__checkbox .form__rating__stars .form__star-icon:last-of-type {
    display: inline-block
}

.form__rating__stars .form__star-icon:first-of-type,
input[type=checkbox]:checked+.form__rating__stars .form__checkbox .form__rating__stars .form__star-icon:first-of-type,
input[type=radio]:checked+.form__rating__stars .form__checkbox .form__rating__stars .form__star-icon:first-of-type {
    display: none
}

.form__rating__stars input[type=radio]:checked+.form__checkbox~.form__checkbox .form__star-icon {
    display: none
}

.form__rating__stars input[type=radio]:checked+.form__checkbox~.form__checkbox .form__star-icon:first-of-type {
    display: inline-block
}

.form--2-col form {
    max-width: 80vw
}

@media all and (min-width: 64rem) {
    .form--2-col form {
        max-width: 54rem
    }
}

.form--2-col div[data-region] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap
}

.form--2-col .form:not(.text-content) {
    margin-top: var(--containerv2-gutter-size, 1.5rem);
    padding-right: .3125rem;
    padding-left: .3125rem;
    flex: 1 1 48%;
    min-width: 300px
}

@media all and (min-width: 64rem) {
    .form--2-col .form:not(.text-content) {
        min-width: 400px
    }
}

.form--2-col .form.text-content {
    margin-top: var(--containerv2-gutter-size, 1.5rem);
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 100%
}

.switch--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6c6c6c
}

.switch--container span {
    font-size: 1.1em;
    font-size: min(max(16px, 1.7708333333vw), 1.125rem);
    font-size: clamp(16px, 1.7708333333vmax, 1.125rem);
    font-weight: 500;
    padding: 0;
    display: block;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-family: var(--typeface-primary), "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0
}

.switch {
    display: flex;
    position: relative;
    margin: 0
}

.switch input {
    margin: 0px;
    margin-top: 1px;
    cursor: pointer;
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    background: red;
    width: 60px;
    height: 30px
}

.a11y .switch input:focus+.label,
.a11y .switch input:hover+.label {
    outline: 3px solid #000
}

.switch input:checked+.label:before {
    background-color: var(--color-primary);
    content: "";
    padding-left: 6px
}

.switch input:checked+.label:after {
    left: 50%
}

.switch input:checked+.label .toggle-on {
    opacity: 1
}

.switch .label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 60px;
    margin: 0
}

.switch .label:before,
.switch .label:after {
    position: absolute;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transition: background-color .3s, left .3s
}

.switch .label:before {
    content: "";
    color: #fff;
    box-sizing: border-box;
    font-family: "FontAwesome", sans-serif;
    padding-left: 23px;
    font-size: 12px;
    line-height: 30px;
    background-color: #adbecb;
    left: 0px;
    top: 0px;
    height: 30px;
    width: 60px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}

.switch .label:after {
    content: "";
    letter-spacing: 20px;
    background: #fff;
    left: 2px;
    top: 50%;
    transform: translate(0, -50%);
    height: calc(100% - 2px);
    width: calc(50% - 2px)
}