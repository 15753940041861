.brand-tiles{
    display: flex;
    flex-wrap: wrap;
    width:100%;
    justify-content:center;
}
.brand-tiles .brand-tile{
    flex: 0 0 auto;
    width: 25%;
    padding-right:15px;
    padding-left:15px;
    margin-top:0;
    text-align:center;
    margin-bottom:30px;
}
.brand-tiles .brand-tile .img-container{
    padding-bottom:100%;
    position:relative;
}
.brand-tiles .brand-tile .img-container > img{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    object-fit:contain;
    object-position: center;
}
.brand-tiles .brand-tile h2{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
    font-size: clamp(13.7500px, 2.44vmax, 30px);
    padding-top:7rem;
    padding-bottom:1rem;
}
.cta{
    text-align: center;
    margin-top:2rem;
    margin-bottom:5rem;
}
@media (max-width: 768px) { 
    .brand-tiles .brand-tile{
        width:50%;
    }
}