#SH12e742cd-abd4-448c-9817-464958cde8d2{
    padding-top: 50px;padding-bottom: 90px;--containerv2-gutter-size: 2.0rem;
 
}
#SH12e742cd-abd4-448c-9817-464958cde8d2 h1{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
}
#SHf5bc9127-20a1-44fc-b1f5-a27f6ffab5f0 h6{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
}

.stashhouse-slideshow__navigation{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    padding:1rem;
    text-align: right;
}
.slide-container{
    position:relative;
}
.slide-container:after{
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:250px;
    background: linear-gradient(0deg, rgba(0,0,0,0.75), transparent 33%);
}
.stashhouse-slideshow__navigation{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    z-index:1;
}
.stashhouse-slideshow__navigation button{
    font-size:2.5rem;
    line-height: 2.5rem;
}
@media(max-width: 48rem) {
    
}

@media(max-width: 34rem) {
   
}