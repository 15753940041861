#b67da1cd-5e3d-468f-85c5-e0de640903a9{
    padding: 75px 0;--containerv2-gutter-size: 1.0rem;
}
#b67da1cd-5e3d-468f-85c5-e0de640903a9 h2{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
}
#SH2ef102d4-d911-485f-85d2-806f62ffe4b6 p span{
    font-size: 50px; font-size: min(max(18.7500px, 3.44vw), 50px); font-size: clamp(18.7500px, 3.44vmax, 50px);
}

body:not(.editor-frame) .image--front-square {
    width: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
}

body:not(.editor-frame) .image--back {
    width: 80%;
    padding-left: 20%;
    padding-bottom: 5%;
    margin-top: 0;
}

body:not(.editor-frame) .link-region--rotate-link {
    position: absolute;
    top: 25%;
    left: -50px;
    animation: mymove linear 12s infinite;
}

@media(max-width: 48rem) {
    body:not(.editor-frame) .link-region--rotate-link {
        display: none;
    }
}

@keyframes mymove {
    100% {
        transform: rotate(360deg);
    }
}
@media(max-width: 48rem) {
    
}

@media(max-width: 34rem) {
   
}