#ff69cf68-3f16-429b-8bf1-93579791911d{
    padding-top: 75px;
    padding-bottom: 8px;
    --containerv2-gutter-size: 3.0rem;
}
.navigation__link {
    font-size: 0.9375em;
    font-weight: 400;
}

.navigation~div>button {
    font-size: 0.9375em;
    font-weight: 400;
    text-transform: none;
}

.containerv2--footerline {
    border-top: 1px solid #292929;
    margin-top: 1rem;
    padding-top: 1rem;
}

.containerv2--footertop .containerv2__region--5 {
    justify-content: flex-end !important;
}

.containerv2--footertop .containerv2__region--5 div {
    margin: 0 !important;
}

.containerv2--footertop .containerv2__region--5 .button {
    min-width: 0;
}

.containerv2--footertop .logo {
    margin: 0.5rem 0 !important;
}
.socialmedia ul {
    display: flex;
    align-items: center;
}
.social-media a {
    width: 46px;
    border: 1px solid #ffffff26;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialmedia {
    margin-left: auto !important;
}

.social-media li+li {
    margin-left: 0.5rem;
    margin-top: 0;
}

.listgrid__title span+span {
    margin-top: 0 !important;
}
.containerv2--login-button-bar .button{
    border-color:#ffffff;
}
#f7eed67a-bd0d-4b9f-b6c1-178df4fc3958{
    margin-right:0;
}
#c3178057-6459-4f7b-81e7-560945935c3c{
    margin-right: -0.5rem!important;
}
.App-social{
    font-size:24px;
}
@media(min-width:69rem) {
    .navigation__list .navigation__list {
        color: #333;
    }
}

.listgrid__item {
    display: block;
   
}

.link-region {
    margin: 0 !important;
}

body:not(.editor-frame) .containerv2--footertop>.containerv2__regions {
    flex-wrap: nowrap;
}

body:not(.editor-frame) .containerv2--footertop>.containerv2__regions>.containerv2__region:nth-child(1) {
    min-width: Min(13rem, 100%);
    align-content: flex-end;
}

body:not(.editor-frame) .containerv2--footertop>.containerv2__regions>.containerv2__region:nth-child(2) {
    display: none;
}

body:not(.editor-frame) .containerv2--footertop>.containerv2__regions>.containerv2__region:nth-child(3) {
    margin: 0;
}

body:not(.editor-frame) .containerv2--footertop>.containerv2__regions>.containerv2__region:nth-child(3) .button {
    padding: 0.5rem;
}

@media(max-width: 34rem) {
    body:not(.editor-frame) .containerv2--footertop>.containerv2__regions {
        flex-wrap: wrap;
    }

    body:not(.editor-frame) .containerv2--footertop>.containerv2__regions>.containerv2__region:nth-child(1),
    body:not(.editor-frame) .containerv2--footertop>.containerv2__regions>.containerv2__region:nth-child(3) {
        justify-content: center !important;
    }

    body:not(.editor-frame) .containerv2--footerline>.containerv2__regions>.containerv2__region {
        text-align: center;
    }

    body:not(.editor-frame) .containerv2--footerline>.containerv2__regions>.containerv2__region .button {
        margin: auto;
    }

    body:not(.editor-frame) .containerv2--login-button-bar>.containerv2__regions>.containerv2__region>div {
        margin: 0;
    }
}

@media(max-width: 48rem) {

    body:not(.editor-frame) .footer__bottom-links-bar,
    body:not(.editor-frame) .footer__bottom-links-bar *+*,
    body:not(.editor-frame) .containerv2--footerline *+* {
        margin-top: 0;
    }

    body:not(.editor-frame) .containerv2--login-button-bar>.containerv2__regions>.containerv2__region {
        flex-direction: column;
        justify-content: center;
    }

    body:not(.editor-frame) .containerv2--footerline .button--login-link {
        margin: 0;
        padding: 0.5rem 1rem;
    }

    body:not(.editor-frame) .containerv2--footerline>.containerv2__regions>.containerv2__region {
        text-align: center;
    }

    body:not(.editor-frame) .containerv2--footerline>.containerv2__regions>.containerv2__region .button {
        margin: auto;
    }

    body:not(.editor-frame) .footer__bottom-links-container {
        text-align: center !important;
    }

    body:not(.editor-frame) .socialmedia {
        margin: 1rem auto auto auto !important;
    }
    .containerv2--login-button-bar .button{
        border-color:transparent;
    }
}

body:not(.editor-frame) footer {
    padding-left: .5rem;
    padding-right: .5rem;
}

.footer__bottom-links-container {
    min-width: 100%;
}

@media(min-width: 100rem) {
    .footer__bottom-links-container *[data-wysiwyg] {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.footer__bottom-links-bar .containerv2__regions {
    align-items: center;
}

.footer__bottom-links-bar .text-content {
    max-width: none;
}

.footer__bottom-links-copyright {
    display: inline-block;
}

.footer__bottom-links-bar .text-content__content *:not(:only-child) {
    margin-right: .5em;
}

ul.footer__bottom-links-list {
    list-style: none !important;
    padding: 0 !important;
    display: inline-block !important;
    column-gap: .8125rem;
    margin-top: 0;
    margin-bottom: 0;
}

.footer__bottom-links-item {
    text-transform: uppercase;
    margin: 0;
    margin-top: 0!important;
    display: inline-block !important;
}

body.editor-frame button.visuallyhidden {
    min-width: 3rem;
    position: static;
}

body:not(.editor-frame) button.visuallyhidden {
    display: none;
}

.sxx-modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sxx-modal,
.sxx-modal:before,
.sxx-modal:after,
.sxx-modal *,
.sxx-modal :before,
.sxx-modal :after {
    box-sizing: border-box;
}

.sxx-modal:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    opacity: 0.85;
}

.sxx-modal__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.667vw;
}

.sxx-modal__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 1rem 1rem 0;
}

.sxx-modal__close:before {
    content: "\2573";
    color: white;
    display: block;
    font-size: 2rem;
    line-height: 1;
}

.sxx-modal__content {
    max-width: 100%;
    max-height: 100%;
    display: flex;
}

.sxx-modal__content>img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.navigation__other-pages.navigation__other-pages--open {
    max-height: 100%;
}

.navigation__other-pages {
    max-height: 0;
    margin: 0 !important;
    padding: 0;
    overflow: hidden;
    transition: max-height 375ms;
}

.navigation__other-pages .navigation__list {
    justify-content: center !important;
}

.footer__other-pages--button {
    margin: auto !important;
    border: none;
}

.navigation__other-pages .navigation__link {
    width: fit-content;
    margin: auto;
}

@media (min-width: 0rem) {

    .navigation__other-pages .navigation__menu,
    .navigation__other-pages .navigation__action {
        display: none;
    }

    .navigation__other-pages--open .navigation__container {
        display: block !important;
        position: static;
        top: auto;
        right: auto;
        max-width: none;
        max-height: none;
        background-color: transparent;
        color: inherit;
    }
}

