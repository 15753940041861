.App-close{
  font-size:30px;
}
[id="de67404c-24e3-43b6-bfcc-aa5bb352b14c"] {
  margin-top: 0 !important;
}

@media all and (max-width: 767px) {
  [id="c311df1f-54ed-4134-94fa-e737920cd7d5"] {
      display: none !important;
  }
}

body:not(.editor-frame) header>.containerv2__regions>.containerv2__region>.link-region>.link-region__link {
    position: relative;
    z-index: 10000;
}

body:not(.editor-frame) header>.containerv2__regions>.containerv2__region {
    justify-content: space-between;
    flex-wrap: nowrap;
}

body:not(.editor-frame) header>.containerv2__regions {
    padding-right: 0;
}

body:not(.editor-frame) header>.containerv2__regions>.containerv2__region>* {
    margin: 0;
}

.mobile--only .navigation__container {
    background-color: var(--color-dark);
    color: #fff;
    max-height: 100vh;
    max-width: 30rem;
    width: 100%;
    top: 0;
    right: 0;
}

@media(min-width: 69rem) {
    body:not(.editor-frame) .mobile--only {
        display: none !important;
    }

    body:not(.editor-frame) header>.containerv2__regions>.containerv2__region>.navigation>.navigation__link,
    body:not(.editor-frame) header>.containerv2__regions>.containerv2__region>*>a {
        padding: 0.875rem;
    }

    body:not(.editor-frame) .naviagtion.desktop--only .navigation__content>.navigation__list>.navigation__item>.navigation__link {
        text-align: center;
    }
}

@media(max-width: 69.0625rem) {
    body:not(.editor-frame) .desktop--only {
        display: none !important;
    }

    body:not(.editor-frame) .navigation__container>.navigation__content>.navigation__list>.navigation__item:nth-child(1)>.navigation__link,
    body:not(.editor-frame) .navigation__container>.navigation__content>.navigation__list>.navigation__item:nth-child(2)>.navigation__link {
        background-color: var(--color-primary);
        font-size: 1.25em;
        text-align: center;
    }

    body:not(.editor-frame) .navigation__container .navigation__item .navigation__list {
        margin-left: 0.5rem;
    }

    .navigation__item+.navigation__item {
        margin-top: 1rem;
    }

    .navigation__item .navigation__item {
        margin-top: 0;
    }

    button.navigation__link {
        font-weight: 600;
    }

    body:not(.editor-frame) button.navigation__link::after {
        content: '';
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 0.5rem;
        transform: rotate(45deg) translateY(-50%);
        -webkit-transform: rotate(45deg) translateY(-50%);
    }

    body:not(.editor-frame) button+.navigation__list {
        max-height: 0;
        overflow: hidden;
        transition: all .5s;
    }

    body:not(.editor-frame) button+.navigation__list--expanded {
        max-height: 20rem;
    }
}

.navigation__close:before {
    content: 'close';
    display: inline-block;
    margin-top:5px;
}
