#SH0d51fab7-333d-477b-b392-43cfd40ca67b{
    padding: 32px 0;
}
#SH0d51fab7-333d-477b-b392-43cfd40ca67b h1{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
    text-align: center;
}
#SHae3deec8-a6fe-4272-8e67-6d79d6965256,
#SH364b7448-32b9-4f9a-821b-4ca7544a5a21,
#SH4fc6d71e-50b7-4b4c-a88c-52ce27ab90b8{
    --containerv2-gutter-size: 1.0rem;
}
.form__legend,
.form--no-label .form__label {
    display: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    background: transparent !important;
    border-bottom: solid 1px !important;
}

label.form__checkbox {
    margin-top: 0;
}

body:not(.editor-frame) .form--added-icon input {
    padding-left: 2rem;
}

body:not(.editor-frame) .form--added-icon.form--row-1_1+.icon,
body:not(.editor-frame) .form--added-icon.form--row-1_2+.icon {
    position: absolute;
    top: 10px;
    margin-top: 0;
}

body:not(.editor-frame) .form--added-icon.form--row-2_2+.icon {
    position: absolute;
    top: calc(50% + 10px + 0.5 * var(--containerv2-gutter-size, 1.5rem));
    margin-top: 0;
}

.input-error input, .input-error select, .input-error textarea{
    border-bottom-color: #C01D1C!important;  
}
.input-error .form__label{
    color:#C01D1C;
}
.input-error .message{
    position: absolute;
    margin-top: 0;
    font-size: .75rem;
    color:#C01D1C;
}
.input-error .form__privacy .message{
    left:50%;
    transform:translateX(-50%);
}
.input-error .icon img{
    filter: invert(30%) sepia(25%) saturate(5407%) hue-rotate(339deg) brightness(66%) contrast(108%);
}
form{
    position:relative;
}
form .sending-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 100%;
    background: rgba(255,255,255,.7);
    margin-top:0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-response-container{
    position:relative;
    background: #ffffff;
    border: 1px solid #54a52c;
    padding: 2rem;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    text-align:center;
}
.form-repsonse__close{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0!important;
    padding: 0.5rem;
  
}
@media screen and (max-width: 768px) {
    body:not(.editor-frame) .form--added-icon.form--row-3_2+.icon {
        top: 80%;
    }

    body:not(.editor-frame) .form--added-icon.form--row-2_2+.icon {
        top: 40%;
    }

    body:not(.editor-frame) .form--added-icon.form--row-last+.icon {
        top: 65%;
    }
}