body:not(.editor-frame) .containerv2--bg-slide-left>.containerv2__background {
    left: 100%;
    transition: all 1s;
    background-color:#000000;
}

body:not(.editor-frame) .containerv2--bg-sliding-left>.containerv2__background {
    left: 0;
}

body:not(.editor-frame) .video__player,
body:not(.editor-frame) .video {
    padding-bottom: 0;
    height: 100%;
}

body:not(.editor-frame) .text-content h1 {
    font-size: clamp(1.5rem, 7vw, 2.5rem);
}

body:not(.editor-frame) .video__player iframe {
    width: auto;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity:.6;
}
#SH996f5d02-6abf-4ea5-9496-89c692295343 h1{
    text-align: center;
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
    font-size: clamp(21.0000px, 3.75vmax, 3.5em);
}

#SH996f5d02-6abf-4ea5-9496-89c692295343 p{
    text-align: center;
    font-size: clamp(1rem, 1.88vmax, 20px);
}
.ytvideowrapper{
    aspect-ratio: 16 / 9;
    position:relative
}
.ytvideowrapper iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:center;
}
.ytvideowrapper + .overlay{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.4);  
    margin-top:0;
}
#SHYT996f5d02-6abf-4ea5-9496-89c692295343{

}
