

body:not(.editor-frame) .containerv2--national-locations .usmap+.text-content {
    position: absolute;
    bottom: 2rem;
    left: calc((100% - 900px) / 2);
    margin-top: auto;
}

body:not(.editor-frame) .containerv2--national-locations .map_key {
    margin-left: 1.5rem;
    position: relative;
    margin-top: 1rem;
}

body:not(.editor-frame) .containerv2--national-locations .map_key:before {
    content: '';
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    background-color: #292929;
    position: absolute;
    left: -1.375rem;
    top: .125rem;
}

body:not(.editor-frame) .containerv2--national-locations .map_key1:before {
    background-color: #3d9b35;
}

body:not(.editor-frame) .containerv2--national-locations .map_key2:before {
    background-color: #77b800;
}

body:not(.editor-frame) .containerv2--national-locations .map_key3:before {
    background-color: #b6d68e;
}

#SH603dfb78-b12e-4bd3-baaf-3b9c9fc8d6d5 h1{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
}

path {
    pointer-events: all;
}
path.AK,
path.HI{
    display: none;
}
path.OK:hover,
path.MO:hover,
path.NM:hover,
path.MS:hover,
path.NJ:hover, 
path.MT:hover{
    opacity: 1;
    fill:#000000;
    cursor: pointer;
}

@media(max-width: 64rem) {
    .us-state-map{
        max-width: 100%;
        height: auto;
    }
    body:not(.editor-frame) .containerv2--national-locations .usmap+.text-content{
        position: relative;
        left: 0;
        bottom: 0;
        margin-left: auto;
        margin-right: 1rem;
        margin-bottom:1rem;
    }
    body:not(.editor-frame) .containerv2--national-locations .usmap+.text-content .text-content__content{
        display:flex;

    }
    body:not(.editor-frame) .containerv2--national-locations .map_key{
        font-size: .7375em;
        margin-left:2.5rem;
    }
    body:not(.editor-frame) .containerv2--national-locations .map_key:before{
        width:1rem;
        height:1rem;
    }
}