.age-gate__text{
    text-shadow: 2px 2px 6px black;
}
.age-gate__text--bad{
    text-shadow: 2px 2px 2px black;
}
body:not(.editor-frame) .age-gate__text--bad{
    display: none;
}

.age-gate-disclaimer{
    font-size: 24px; 
    font-size: min(max(1rem, 2.08vw), 24px); 
    font-size: clamp(1rem, 2.08vmax, 24px);
    text-align:center;
}

.age-gate__container{
    min-height:100vh!important;
}