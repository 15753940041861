.listgrid__image.image--full {
    width:14.5rem;
    height:14.5rem;
    max-width: 150px;
    max-height: 150px;
    border: none;
}

.listgrid__list {
    display: grid;
    column-gap: 0rem !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.listgrid__item {
    border-bottom: 0.5px solid white;
    padding-bottom: 1.5rem;
}

.listgrid__image>.image__img {
    opacity: 0.2;
    transition: 0.3s;
}

.listgrid__item:hover img {
    opacity: 1;
}

#SH52efb4c9-1a47-4dd4-ae90-e6adaaf1a281 h1{
    text-align: left;
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
}
#c7e5caf8-fb6e-48ef-823f-07fd8154bfb3{
    padding-top: 24px;background-color: #e4e4e4;
}
@media(max-width: 40rem) {
    .listgrid__list {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
    .listgrid__image>.image__img {
        opacity: 1;
    }
}