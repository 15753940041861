.containerv2--no-text-margin .text-content .text-content__content>* {
    margin: 0;
}

body:not(.editor-frame) script,
body:not(.editor-frame) script:first-child+* {
    margin-top: 0;
}

.containerv2--gutter-l>.containerv2__regions>.containerv2__region:first-child:not(:only-child) {
    margin-right: var(--containerv2-gutter-size, 1.5rem);
}

.containerv2--gutter-l>.containerv2__regions>.containerv2__region:last-child:not(:only-child) {
    margin-left: 0;
}

@media(max-width: 64rem) {
    body:not(.editor-frame) .text--icon-title {
        display: flex;
        flex-wrap: wrap;
    }
}

body:not(.editor-frame) .text--icon-title>*:first-child {
    margin-right: 0.5rem;
}

#SH2f7f257a-a35e-4d6c-83dd-d1e845e72c51{
    padding: 75px 0;--containerv2-gutter-size: 1.0rem;
}
#SH0271ba29-432d-40b9-ba38-48d9c574c6df{
    --containerv2-gutter-size: 1.0rem;
}
#SH2f7f257a-a35e-4d6c-83dd-d1e845e72c51 h1{
    font-family: montserrat, helvetica neue, helvetica, arial, sans-serif;
}
#SH2f7f257a-a35e-4d6c-83dd-d1e845e72c51 h6{
    font-size: 12px; font-size: min(max(1rem, 1.46vw), 12px); font-size: clamp(1rem, 1.46vmax, 12px); font-family: montserrat, 'helvetica neue', helvetica, arial, sans-serif;
}
.icon{
    fill:#ffffff;
}
#SH2f7f257a-a35e-4d6c-83dd-d1e845e72c51 h1{
    text-align:left;
}
#SH3c130862-af9f-40fd-8397-c177aff27e6a .icon{
    height:50px;
    width:auto;
}